import React, { FC, ReactElement } from 'react';

import { BackgroundImageWorld, CounterLocation } from '../../components';
import { LocationMap } from '../../entities/LocationMap/LocationMap';
import trans from '../../helpers/trans';
import { calculateTotal } from './helpers';

import './LocationMapContainer.scss';

interface LocationMapContainerProps {
    locationsValue?: LocationMap;
    className?: string;
}

const LocationMapContainer: FC<LocationMapContainerProps> = ({ locationsValue, className = '' }): ReactElement => {
    const totalLocationValue = calculateTotal(locationsValue);

    if (!locationsValue) {
        return (
            <div className={`location-map-container ${className}`}>
                <BackgroundImageWorld className="location-map-container__image" />
            </div>
        );
    }

    return (
        <div className={`location-map-container ${className}`}>
            <BackgroundImageWorld
                className="location-map-container__image"
            />

            <CounterLocation
                animationDelay={1}
                locationName={trans('containers.locationMapContainer.northAmerica')}
                locationNumber={locationsValue.america}
                className="location-map-container__north-america"
            />

            <CounterLocation
                animationDelay={2}
                locationName={trans('containers.locationMapContainer.westernEurope')}
                locationNumber={locationsValue.westernEurope}
                className="location-map-container__western-europe"
            />

            <CounterLocation
                animationDelay={3}
                locationName={trans('containers.locationMapContainer.easternEurope')}
                locationNumber={locationsValue.easternEuropeRussia}
                className="location-map-container__eastern-europe"
            />

            <CounterLocation
                animationDelay={4}
                locationName={trans('containers.locationMapContainer.asiaPacific')}
                locationNumber={locationsValue.apacMiddleEastAfrica}
                className="location-map-container__asia-pacific"
            />

            <div className="location-map-container__total-number-wrapper">
                <CounterLocation
                    animationDelay={1}
                    locationName={trans('containers.locationMapContainer.locationsWorldWide')}
                    locationNumber={totalLocationValue}
                    className="location-map-container__total-number"
                    titleClassName="location-map-container__total-number-title"
                />
            </div>
        </div>
    );
};

export default LocationMapContainer;
