import React, { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedLocationContainer } from '../../connectors';

interface LocationMapProps {
    className?: string;
}

const LocationMapPage: FC<LocationMapProps> = ({ className }): ReactElement => (
    <Page>
        <ConnectedLocationContainer className={className} />
    </Page>
);

export default LocationMapPage;
