import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import Odometer from 'react-odometerjs';

import './CounterLocation.scss';

interface CounterLocationProps {
    locationNumber: number;
    locationName?: string;
    animationDelay: number;
    className?: string;
    titleClassName?: string;
}

const CounterLocation: FC<CounterLocationProps> = ({
    locationNumber,
    locationName,
    animationDelay,
    className = '',
    titleClassName = '',
}): ReactElement => {
    const [odoMeterValue, setOdoMeterValue] = useState(0);

    useEffect((): () => void => {
        const timeoutId = setTimeout(() => setOdoMeterValue(locationNumber), animationDelay * 1000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div className={`container-location ${className}`}>
            <Odometer
                value={odoMeterValue}
                format="dd"
                className="container-location__counter"
            />

            <h5 className={`container-location__title ${titleClassName}`}>
                {locationName}
            </h5>
        </div>
    );
};

export default CounterLocation;
