import { fetchUrl } from '../../helpers/fetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { LocationMap, LocationMapResource } from './LocationMap';
import { transformToLocation } from './LocationMapTransformers';

export const getLocationsApiCall = async (): Promise<FetchCollectionResult<LocationMap, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: '/locations/video-wall',
        });

        const response = await fetchUrl(url);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const locationResources: LocationMapResource = await response.json();

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: transformToLocation(locationResources),
        };
    } catch (error: unknown) {
        console.error('[getLocationsApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
