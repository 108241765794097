import { combineReducers } from 'redux';

import articlesReducer, { ArticlesState } from './articles/arcticlesReducer';
import locationReducer, { LocationState } from './locations/locationsReducer';

export interface Reducers {
    articlesReducer: ArticlesState,
    locationReducer: LocationState,
}

export default combineReducers<Reducers>({
    articlesReducer,
    locationReducer,
});
