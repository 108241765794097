import { fetchUrl } from '../../helpers/fetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { Article, ArticleResource } from './Article';
import { transformToArticle } from './ArticleTransformers';

export const getArticlesApiCall = async (tag?: string): Promise<FetchCollectionResult<Article[], string>> => {
    try {
        const url = generateApiUrl({
            endpoint: '/articles/video-wall',
            queryParams: tag ? { tag } : undefined,
        });

        const response = await fetchUrl(url);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const articlesResource: ArticleResource[] = await response.json();

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: articlesResource.map(transformToArticle),
        };
    } catch (error: unknown) {
        console.error('[getArticlesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

