import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getLocationsApiCall } from '../../entities/LocationMap/LocationMapService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setLocations } from './locationsReducer';

export const getLocations = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const response = await getLocationsApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setLocations(response.data));
    } catch (error) {
        console.error('[getLocations]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
