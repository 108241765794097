import React, { FC, ReactElement, useEffect } from 'react';

import LocationMapContainer from '../../containers/LocationMapContainer/LocationMapContainer';
import { getLocations } from '../../redux/locations/locationActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedLocationMapContainerProps {
    className?: string;
}

const ConnectedLocationMapContainer: FC<ConnectedLocationMapContainerProps> = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const { location } = useTypedSelector(state => state.locationReducer);

    useEffect(() => {
        if (!location) {
            dispatch(getLocations());
        }
    }, []);

    return (
        <LocationMapContainer locationsValue={location} />
    );
};

export default ConnectedLocationMapContainer;

