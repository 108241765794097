import React, { FC, ReactElement } from 'react';

import trans from '../../helpers/trans';

import './BackgroundImageWorld.scss';

interface BackgroundImageWorldProps {
    className?: string;
}

const BackgroundImageWorld: FC<BackgroundImageWorldProps> = ({ className = '' }): ReactElement => (
    <picture className={`background-image-world ${className}`}>
        <source srcSet="./worldmap.webp" type="image/webp" />
        <source srcSet="./worldmap.jpeg" type="image/jpeg" />
        <img
            src="./VideoWall-map@4x.png"
            alt={trans('components.backgroundImageWorld.alt')}
            className="background-image-world__image"
        />
    </picture>
);

export default BackgroundImageWorld;
