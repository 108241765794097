import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LocationMap } from '../../entities/LocationMap/LocationMap';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type LocationState = AsyncReduxState<{
    location?: LocationMap;
}>;

const initialState: LocationState = {
    ...initialAsyncReduxState,
    location: undefined,
};

export const LocationSlice = createSlice({
    name: 'locationReducer',
    initialState,
    reducers: {
        setError(state, action: PayloadAction<string>): LocationState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): LocationState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setLocations(state, action: PayloadAction<LocationMap>): LocationState {
            return {
                ...state,
                location: action.payload,
            };
        },
    },
});

export const {
    setLocations,
    setError,
    setIsLoading,
} = LocationSlice.actions;

export default LocationSlice.reducer;
