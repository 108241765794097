import React, { FC, ReactElement, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { VideowallContainer } from '../../containers';
import { getArticles } from '../../redux/articles/articlesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedVideowallContainerProps {
    className?: string;
}

const ConnectedVideowallContainer: FC<ConnectedVideowallContainerProps> = ({ className = '' }): ReactElement => {
    const [searchParams] = useSearchParams();
    const tag = searchParams.get('tag') ?? undefined;

    const dispatch = useTypedDispatch();
    const { articles } = useTypedSelector(state => state.articlesReducer);

    useEffect((): void => {
        if (articles.length === 0) {
            dispatch(getArticles(tag));
        }
    }, [articles]);

    return (
        <VideowallContainer
            articles={articles}
            className={className}
        />
    );
};

export default ConnectedVideowallContainer;
